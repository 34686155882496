import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/Service.css";
import p9png from "../images/p9.png";
import { Link } from "react-router-dom";
function Service() {
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    axios
      .get("https://web.api.lissomtech.in/api/getservices")

      .then((response) => {
        console.log("Fetched Data:", response.data);
        setServicesData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getColorClass = (index) => {
    switch (index % 3) {
      case 0:
        return "align-items-center1";
      case 1:
        return "align-items-center2";
      case 2:
        return "align-items-center3";
      default:
        return "align-items-center1";
    }
  };
  return (
    <div
      className="container-fluid"
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "rgb(243 244 246 )",
        marginTop: "40px",
      }}
    >
      <h1 className="textCenterCustom">Services We Offer</h1>
      <br></br>

      <div className="row">
        {servicesData.map((service, index) => (
          <div key={index} className="col-md-4" style={{ marginTop: "20px" }}>
            <Link to={`/${service.route}`} className="learn-more-link">
              <div className={`card servicecard`} style={{ marginLeft: "0px" }}>
                <div
                  className={`card-body d-flex justify-content-start ${getColorClass(
                    index
                  )}`}
                >
                  <img
                    src={p9png}
                    className="card-img"
                    alt={`Image ${index + 1}`}
                    style={{
                      height: "64px",
                      width: "64px",
                      borderRadius: "50px",
                      marginLeft: "8px",
                    }}
                  />
                  <div className="ml-3">
                    <h5 style={{ textAlign: "center", marginLeft: "20px" }}>
                      {service.Service_Name}
                    </h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Service;
