import React, { useEffect, useState } from "react";
import "../css/Footer.css";
import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import axios from "axios";
import vd from "../videos/foot.mp4";
import { IoMdMail } from "react-icons/io";
import { MdCall } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  const [logoData, setLogoData] = useState([]);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    axios
      .get("https://web.api.lissomtech.in/api/getlogos")
      .then((response) => {
        console.log("Fetched Data:", response.data);
        setLogoData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="footer-container">
      <video autoPlay loop muted className="footer-video">
        <source src={vd} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="card main">
        <div className="main-footer">
          <div className="container">
            <div className="row">
              {logoData.map((data, index) => (
                <div key={index} className="col-md-12 col-lg-3">
                  <a
                    className="d-inline-block"
                    style={{ marginRight: "140px" }}
                  >
                    <img
                      src={`https://web.api.lissomtech.in/${data.logo}`}
                      alt="Logo"
                      className="img-fluid mx-auto"
                      style={{
                        maxWidth: "250px",
                        height: "auto",

                        marginBottom: "30px",
                        marginTop: "120px",
                      }}
                    />
                  </a>
                </div>
              ))}
              {/* Column 1 */}
              <div className="column col-md-6 col-lg-3">
                <h3 className="Footertitle v-text">WHO WE ARE</h3>
                <hr />
                <ul className="list-unstyled">
                  Lissom Technologies Pvt. Ltd. was founded in the 2015 as a
                  company By technology Specialists in Pune. Over a period of 7
                  Years, We have successfully become a Website and software
                  development partner for many small and medium enterprises.
                </ul>
              </div>
              {/* Column 2 */}
              <div className="column  col-md-6 col-lg-2">
                <h3 className="Footertitle v-text">USEFUL LINKS</h3>
                <hr />
                <ul className="list-unstyled">
                  <li>HOME</li>
                  <li>
                    <Link to="/aboutus" className="list-unstyled">
                      ABOUT US
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/services" className="list-unstyled">
                      SERVICES WE OFFER
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/gallery" className="list-unstyled"></Link>{" "}
                    GALLERY
                  </li>
                  <li>
                    {" "}
                    <Link to="/projects" className="list-unstyled">
                      PROJECTS
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/career" className="list-unstyled">
                      CAREERS
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/events" className="list-unstyled">
                      EVENTS
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/aboutus" className="list-unstyled">
                      CONTACT US
                    </Link>{" "}
                  </li>
                </ul>
              </div>
              {/* Column 3 */}
              <div className="column col-md-6 col-lg-3">
                <h3 className="Footertitle v-text">CONTACT US</h3>
                <hr />
                <address>
                  <strong>
                    {" "}
                    <FaLocationDot /> Lissom Technologies Pvt. Ltd
                  </strong>
                  <br></br>
                  R.H. 01, Richmond Park,<br></br>
                  Opposite Orchid School,<br></br>
                  Baner, Pune 411045.<br></br>
                  <strong className="mail ">
                    <IoMdMail /> :
                  </strong>{" "}
                  info@lissomtechnologies.com
                  <br />
                  <strong className="contact">
                    <MdCall /> :{" "}
                  </strong>
                  +91-8380027766, 8380037766
                </address>
              </div>
              {/* Column 4 */}
              <div className="column col-md-6 col-lg-1">
                {/* <h3 className="Footertitle" style={{color:'rgb(105, 105, 105)'}} >.</h3>
                <hr />
                <a className="afooter" href="https://www.facebook.com/Lissomtechnologiespvtltd">
                <FaFacebook size={24} color="black" />
              </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="card botm">
          <div className="row ">
            <div className="col-12 align-self-center">
              <p className="pname ">
                © {currentYear}
                <a
                  className="pname"
                  href="https://lissomtechnologies.com/"
                  style={{ color: "black" }}
                >
                  Copyright Lissom Technologies Pvt. Ltd. All Rights Reserved.
                </a>
              </p>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <a className="afooter " href="https://www.facebook.com/...">
                <FaFacebook size={24} color="black" />
              </a>
              <a className="afooter" href="https://wa.me/9420950999">
                <FaWhatsapp size={24} color="black" />
              </a>
              <a className="afooter" href="https://www.instagram.com/...">
                <FaInstagram size={24} color="black" />
              </a>
              <a className="afooter" href="https://www.linkedin.com/...">
                <FaLinkedin size={24} color="black" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
