// import React, { useEffect, useState } from "react";
// import "../css/SubProjects.css";
// import Projectvd from "../videos/project (2).mp4";
// import axios from "axios";
// import { FaArrowRight } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { Card, Col, Form } from "react-bootstrap";
// import Footer from "../components/Footer";
// // import ApiConfig from "./ApiConfig";

// function SubProjects() {
//   const imgStyle = {
//     height: "200px",
//     objectFit: "cover",
//   };

//   const [projectData, setProjectData] = useState([]);

//   useEffect(() => {
//     axios
//       .get("https://web.api.lissomtech.in/api/projects")

//       .then((response) => {
//         console.log("Fetched Data:", response.data);

//         setProjectData(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const handlePublishToggle = (projectId, checked) => {
//     console.log(`ProjectID: ${projectId} - Published: ${checked}`);
//   };
//   const formatDate = (isoDate) => {
//     const date = new Date(isoDate);
//     return date.toISOString().split("T")[0];
//   };

//   const [projects, setProjects] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("All");

//   useEffect(() => {
//     axios
//       .get("https://web.api.lissomtech.in/projects")
//       .then((response) => {
//         setProjects(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching projects:", error);
//       });
//   }, []);

//   const filterProjects = (category) => {
//     setSelectedCategory(category);
//   };

//   return (
//     <div className="container-fluid" style={{ padding: "0px" }}>
//       <br></br>
//       <div class="header-intro">
//         <Card className="video-card">
//           <video autoPlay muted loop controls={false}>
//             <source src={Projectvd} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </Card>
//       </div>
//       <br></br>

//       {/* Add navigation links as an unordered list */}
//       <ul className="nav justify-content-center">
//         <li className="nav-item gallary">
//           <button
//             className={`nav-link ${selectedCategory === "All" ? "active" : ""}`}
//             onClick={() => filterProjects("All")}
//           >
//             All Project
//           </button>
//         </li>
//         <li className="nav-item gallary">
//           <button
//             className={`nav-link ${
//               selectedCategory === "Completed" ? "active" : ""
//             }`}
//             onClick={() => filterProjects("Completed")}
//           >
//             Completed Project
//           </button>
//         </li>
//         <li className="nav-item gallary">
//           <button
//             className={`nav-link ${
//               selectedCategory === "live" ? "active" : ""
//             }`}
//             onClick={() => filterProjects("live")}
//           >
//             Live Project
//           </button>
//         </li>
//         <li className="nav-item gallary">
//           <button
//             className={`nav-link ${
//               selectedCategory === "Upcoming" ? "active" : ""
//             }`}
//             onClick={() => filterProjects("Upcoming")}
//           >
//             Upcoming Project
//           </button>
//         </li>
//       </ul>

//       <div className="container" style={{ padding: "0px" }}>

//         <br></br>

//         <div className="row">
//           {projects
//             .filter((project) => {
//               if (selectedCategory === "All") {
//                 return project.Published;
//               } else {
//                 const projectCategories = project.categories
//                   ? project.categories.split(",").map((cat) => cat.trim())
//                   : [];
//                 return (
//                   projectCategories.includes(selectedCategory) &&
//                   project.Published
//                 );
//               }
//             })
//             .map((project, index) => (
//               <div
//                 className={`col-md-4 ${index >= 3 ? "mt-4" : ""}`}
//                 key={index}
//               >
//                 <Link className="learn-more-link" to="subprojects">
//                   <div className="card">
//                     <img

//                       src={`https://web.api.lissomtech.in/${project.file_img}`}
//                       className="card-img-top"
//                       alt={`Project Image ${index + 1}`}
//                       style={imgStyle}
//                     />
//                     <div className="card-body">
//                       <h4 className="card-title">{project.Project_Name}</h4>
//                       <p className="card-text">{project.Project_Description}</p>
//                       <br />
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//             ))}
//         </div>
//       </div>
//       <br></br>
//       <br></br>
//       <Footer />
//     </div>
//   );
// }

// export default SubProjects;

import React, { useEffect, useState } from "react";
import "../css/SubProjects.css";
import Projectvd from "../videos/project (2).mp4";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Card, Col, Form } from "react-bootstrap";
import Footer from "../components/Footer";
// import ApiConfig from "./ApiConfig";

function SubProjects() {
  const imgStyle = {
    height: "200px",
    objectFit: "cover",
  };

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    axios
      .get("https://web.api.lissomtech.in/api/projects")

      .then((response) => {
        console.log("Fetched Data:", response.data);

        setProjectData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handlePublishToggle = (projectId, checked) => {
    console.log(`ProjectID: ${projectId} - Published: ${checked}`);
  };
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };

  const [projects, setProjects] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    axios
      .get("https://web.api.lissomtech.in/projects")
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  const filterProjects = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="container-fluid" style={{ padding: "0px" }}>
      <br></br>
      <div class="header-intro">
        <Card className="video-card">
          <video autoPlay muted loop controls={false}>
            <source src={Projectvd} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Card>
      </div>
      <br></br>

      {/* Add navigation links as an unordered list */}
      <ul className="nav justify-content-center">
        <li className="nav-item gallary">
          <button
            className={`nav-link ${selectedCategory === "All" ? "active" : ""}`}
            onClick={() => filterProjects("All")}
          >
            All Project
          </button>
        </li>
        <li className="nav-item gallary">
          <button
            className={`nav-link ${
              selectedCategory === "Completed" ? "active" : ""
            }`}
            onClick={() => filterProjects("Completed")}
          >
            Completed Project
          </button>
        </li>
        <li className="nav-item gallary">
          <button
            className={`nav-link ${
              selectedCategory === "live" ? "active" : ""
            }`}
            onClick={() => filterProjects("live")}
          >
            Live Project
          </button>
        </li>
        <li className="nav-item gallary">
          <button
            className={`nav-link ${
              selectedCategory === "Upcoming" ? "active" : ""
            }`}
            onClick={() => filterProjects("Upcoming")}
          >
            Upcoming Project
          </button>
        </li>
      </ul>

      <div className="container" style={{ padding: "0px" }}>
        <br></br>

        <div className="row">
          {projects
            .filter((project) => {
              if (selectedCategory === "All") {
                return project.Published;
              } else {
                const projectCategories = project.categories
                  ? project.categories.split(",").map((cat) => cat.trim())
                  : [];
                return (
                  projectCategories.includes(selectedCategory) &&
                  project.Published
                );
              }
            })
            .map((project, index) => (
              <div
                className={`col-md-4 ${index >= 3 ? "mt-4" : ""}`}
                key={index}
              >
                <Link className="learn-more-link" to="subprojects">
                  <div className="card">
                    <img
                      src={`https://web.api.lissomtech.in/${project.file_img}`}
                      className="card-img-top"
                      alt={`Project Image ${index + 1}`}
                      style={imgStyle}
                    />
                    <div className="card-body">
                      <h4 className="card-title">{project.Project_Name}</h4>
                      <p className="card-text">{project.Project_Description}</p>
                      <br />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
      <br></br>
      <br></br>
      <Footer />
    </div>
  );
}

export default SubProjects;
