import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Card, Container, Row, Col, Form } from "react-bootstrap";

const Carousel = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(5);

  useEffect(() => {
    fetchGalleryData();
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const fetchGalleryData = async () => {
    try {
      const response = await axios.get(
        "https://web.api.lissomtech.in/api/getclients"
      );

      setGalleryData(response.data);
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    }
  };

  const updateSlidesToShow = () => {
    const isMobile = window.innerWidth < 768;
    setSlidesToShow(isMobile ? 3 : 5);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    beforeChange: (current, next) => {},
  };

  const [imageHeight, setImageHeight] = useState("200px");

  useEffect(() => {
    fetchGalleryData();
    updateImageHeight();
    window.addEventListener("resize", updateImageHeight);
    return () => window.removeEventListener("resize", updateImageHeight);
  }, []);

  const updateImageHeight = () => {
    const newHeight = window.innerWidth < 768 ? "100px" : "180px";
    setImageHeight(newHeight);
  };

  return (
    <div className="container">
      <h1 className="ourclient text-center" style={{ marginTop: "30px" }}>
        Our-clients
      </h1>
      <br />

      <div className="row">
        <Slider {...settings}>
          {galleryData.map((data) => (
            <div>
              <Card
                style={{
                  margin: "30px",
                  boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
                  border: "none",
                }}
              >
                <Card.Img
                  variant="top"
                  src={`https://web.api.lissomtech.in/${data.Ourclients}`}
                  style={{
                    height: imageHeight,
                    objectFit: "cover",
                    width: "100%",
                  }}
                  key={data.id}
                />
              </Card>
            </div>
          ))}
        </Slider>
      </div>
      <br></br>
    </div>
  );
};

export default Carousel;
