import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Card, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
const InvitationLetter = () => {
  const { tenderNo } = useParams();

  const [tender, setTender] = useState(null);
  const [tenders, setTenders] = useState([]);
  const [expiryDate, setExpiryDate] = useState(null);
  const [shipTo, setShipTo] = useState("");

  const fetchTenderByNumber = async (tenderNo) => {
    try {
      const response = await fetch(
        `https://web.api.lissomtech.in/get_tender?tenderNo=${tenderNo}`
      );
      const data = await response.json();
      setTender(data[0]);
    } catch (error) {
      console.error("Error fetching tender:", error);
    }
  };

  useEffect(() => {
    if (tenderNo) {
      fetchTenderByNumber(tenderNo);
    }
  }, [tenderNo]);
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };

  const containerStyles = {
    padding: "0",
    position: "relative",
  };

  const projectDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    fetchTenderByNumber();
  }, [tenderNo]);

  return (
    <div className="invitation-letter">
      <Card>
        <header>
          <div className="company-logo">
            <img
              alt="Logo"
              className="img-fluid mx-auto"
              style={{
                maxWidth: "250px",
                height: "auto",
                marginBottom: "30px ",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                marginTop: "120px",
              }}
            />
          </div>
        </header>
        <div className="recipient-info">
          <h2>Matoshree Interiors</h2>
          <br />
          <p>
            Behind Kalasai Hotel, Opp. Police Station, Shirdi, Tal-Rahata
            Dist-Ahmednagar, Maharashtra, India. Pincode 423107
          </p>
        </div>
        <div className="invitation-text">
          <p>Dear All,</p>
          <p>
            We are writing to invite you to submit a tender for the provision of
            goods/services at {shipTo}.
          </p>
          <p>
            Please refer to Appendix 1 for the detailed specifications of the
            goods/services required.
          </p>
          {expiryDate && (
            <p>
              The deadline for submitting tenders is {formatDate(expiryDate)}.
            </p>
          )}
          <p>We look forward to receiving your tender.</p>
          <p>Sincerely,</p>
          <p>Matoshree Interior</p>
        </div>
      </Card>

      <Container fluid style={containerStyles}>
        <Row>
          {tender && (
            <Card className="form-section">
              <Card.Body>
                <Card.Title style={{ fontSize: "25px" }}>
                  <h1>Specifications for Tender {tender.TenderNo}:</h1>
                </Card.Title>
                <br></br>
                <div className="container">
                  <div className="row">
                    <div className="col-4">
                      <div className="form-field">
                        <h5 className="label">Tender No: {tenderNo}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          Tender Date:{projectDate(tender.TenderDate)}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          CustomerID:{tender.CustomerID}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          ProjectID : {tender.ProjectID}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">Bill_To : {tender.Bill_To}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">Ship_To : {tender.Ship_To}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          Expiry_Date:{projectDate(tender.Expiry_Date)}{" "}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          Reference : {tender.Reference}
                        </h5>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="form-field">
                        <h5 className="label"> Status : {tender.Status}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label"> Currency : {tender.Currency}</h5>
                      </div>
                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          Sales_Agent : {tender.Sales_Agent}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          Discount_Type :{tender.Discount_Type}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          Admin_Note:{tender.Admin_Note}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label"> Status1 : {tender.Status1}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          Tender Date: {formatDate(tender.TenderDate)}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label"> ShowQty : {tender.ShowQty}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          Created_by : {tender.Created_by}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          BillingTypeID : {tender.BillingTypeID}
                        </h5>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-field">
                        <h5 className="label"> SaveAs : {tender.SaveAs}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label"> Bank : {tender.Bank}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label"> Cash : {tender.Cash}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label"> reminder : {tender.reminder}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label"> TotalTax : {tender.TotalTax}</h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          TotalTaxPER : {tender.TotalTaxPER}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          TotalDicountPer : {tender.TotalDicountPer}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          TotalDicountAmont : {tender.TotalDicountAmont}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          SubCostTotalAmont : {tender.SubCostTotalAmont}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          TenderTotalAmont : {tender.TenderTotalAmont}
                        </h5>
                      </div>

                      <div className="form-field">
                        <h5 className="label">
                          {" "}
                          AdjustmentCost :{tender.AdjustmentCost}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
              </Card.Body>
              <button type="button" className="btn btn-dark">
                Apply
              </button>
            </Card>
          )}
        </Row>
        <hr style={{ marginTop: "50px" }} />
      </Container>

      <Footer />
    </div>
  );
};

export default InvitationLetter;
