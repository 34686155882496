import React from "react";

function Team(){
    return(
        <div className="row">
        <div className="col-md-3">
            <div class="card" >
                <img  class="card-img-top" alt="..." style={{ height: "200px" }} />

            </div>
        </div>
        <div className="col-md-3">
            <div class="card" >
                <img  class="card-img-top" alt="..." style={{ height: "200px" }} />

            </div>
        </div>
        <div className="col-md-3">
            <div class="card" >
                <img  class="card-img-top" alt="..." style={{ height: "200px" }} />

            </div>
        </div>
        <div className="col-md-3">
            <div class="card" >
                <img  class="card-img-top" alt="..." style={{ height: "200px" }} />

            </div>
        </div>
    </div>
    )
}
export default Team;