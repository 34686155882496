import React from "react";
import { Card, Button } from "react-bootstrap";

const GalleryCard = ({ image }) => (
  <Card style={{ margin: "10px" }}>
    <Card.Img
      variant="top"
      src={`https://web.api.lissomtech.in/${image}`}
      alt={image}
      style={{ height: "200px", objectFit: "cover", width: "100%" }}
    />
  </Card>
);

export default GalleryCard;
