import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "../css/CardSlider.css";
import Card from "react-bootstrap/Card";
import axios from "axios";

const CardSlider = () => {
  const sliderRef = useRef(null);
  const [cards, setCards] = useState([
    { title: "Who We Are", column: "whoWeAre" },
    { title: "Our Mission", column: "ourMission" },
    { title: "Our Vision", column: "ourVision" },
    { title: "Expertise", column: "experties" },
    { title: "Quality Craftsmanship", column: "quality" },
    { title: "Client-Centric Approach", column: "clientApproch" },
    { title: "Timely Delivery", column: "timelyDelivery" },
  ]);

  const cardHeaderStyle1 = {
    marginBottom: "20px",
    fontFamily: "Courgette",
    fontSize: "20px",
    lineHeight: "1.1",
    color: "#ffa500",
    margin: "0",
  };

  const cardTitleStyle1 = {
    fontFamily: "Montserrat",
    fontWeight: 450,
    fontSize: "33px",
    lineHeight: 1.7,
    color: "black",
    margin: "0px",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://web.api.lissomtech.in/homeData"
        );
        if (response.data.success) {
          // Map the fetched data to merge with the static titles
          const mergedData = cards.map((card) => ({
            ...card,
            text: response.data.data[0][card.column], // Fetch text from the specified column
          }));
          setCards(mergedData);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Card.Header style={{ marginTop: "50px" }}>
        <h1 style={cardTitleStyle1}>About Lissom Technologies</h1>
        <h3 style={cardHeaderStyle1}>Your Vision, Our Innovation</h3>
      </Card.Header>

      <div className="card-slider-container">
        <Slider {...settings} ref={sliderRef}>
          {cards.map((card, index) => (
            <div key={index}>
              <h4>{card.title}</h4>
              <p style={{ marginRight: "50px", marginLeft: "50px" }}>
                {card.text}
              </p>
            </div>
          ))}
        </Slider>
        <button
          className="slider-button slider-button-left"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <FaChevronLeft />
        </button>
        <button
          className="slider-button slider-button-right"
          onClick={() => sliderRef.current.slickNext()}
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default CardSlider;
