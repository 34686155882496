import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/Projects.css";
import p4png from "../images/pexels-ninosouza-2800552.jpg"; // Default image in case of error
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function Projects() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios
      .get("https://web.api.lissomtech.in/projects")
      .then((response) => {
        setProjects(response.data.slice(0, 3)); // Get only the first 3 projects
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  return (
    <div className="container" style={{ marginTop: "50px", padding: "0px" }}>
      <h1 className="text-center">Our Renowned Projects</h1>
      <br></br>
      <div className="header-intro">
        <div className="image-container">
          <img
            src={p4png}
            className="card-img"
            alt="Featured Project"
            style={{ height: "380px" }}
          />
        </div>
        <div className="text-container">
          {/* Add text content here if needed */}
        </div>
      </div>

      <br></br>
      <br></br>
      <div className="row">
        {projects.map((project, index) => (
          <div className={`col-md-4 ${index >= 3 ? "mt-4" : ""}`} key={index}>
            <Link className="learn-more-link" to="subprojects">
              <div className="card" style={{ height: "480px" }}>
                <img
                  src={`https://web.api.lissomtech.in/${project.file_img}`} // Ensure your server is serving images correctly
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = p4png;
                  }} // Fallback image
                  className="card-img-top"
                  alt={`Project Image ${index + 1}`}
                  style={{ height: "250px" }} // Adjust image height as needed
                />
                <div className="card-body">
                  <h4 className="card-title">{project.Project_Name}</h4>
                  <p className="card-text">{project.Project_Description}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

export default Projects;
