
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Projects from "./Projects";
import HomeVideo from "./HomeVideo";
import CardSlider from "./CardSlider";
import Service from "./Service";
import Slider from "./Slider";
import ReviewSlider from "./ReviewSlider";
import EnquiryForm from "./EnquiryForm";
import Navbars from "./Navbars";
import Footer from "./Footer";
import "../css/Home.css";
import Ourclients from "../subcomponent/Ourclients";
const Home = () => {
  return (
    <div className="component-class">
      <Row>
        <Col xs={12}>
          <Navbars />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <HomeVideo />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <CardSlider />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Service />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Projects />
        </Col>
      </Row>

      {/* <Row>
        <Col xs={12}>
          <Slider />
        </Col>
      </Row> */}

      <Row>
        <Col xs={12}>
          <ReviewSlider />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <EnquiryForm />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Ourclients />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Footer />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
