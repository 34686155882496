// import React from 'react';
// import home from '../videos/bg3.mp4';
// import '../css/Homevideo.css';
// import Card from 'react-bootstrap/Card';

// const HomeVideo = () => {
//     return (
//         <>
//             <Card className="mt-md-0" style={{background:'whitesmoke',border:'none'}}>
//                 <Card.Body>
//                     <div className="video-container">
//                         <video controls={false} className='myvideo' autoPlay disablePictureInPicture loop muted>
//                             <source src={home} type="video/mp4" />
//                             </video>
//                         <div className="video-text">
//                             <h1>Welcome to</h1>
//                             <h1>Lissom Technologies</h1>
//                         </div>
//                     </div>
//                 </Card.Body>
//             </Card>
//         </>
//     );
// };

// export default HomeVideo;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import Card from "react-bootstrap/Card";
import "../css/Homevideo.css";

const HomeVideo = () => {
  return (
    <>
      <Card
        className="mt-md-0"
        style={{ background: "whitesmoke", border: "none" }}
      >
        <Card.Body>
          <div className="video-container">
            <div className="video-wrapper">
              <iframe
                src="https://player.vimeo.com/video/476441166?h=a01eb7df32&autoplay=1&loop=1&background=1&muted=1"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className="myvideo"
              ></iframe>
            </div>
            <div className="video-text">
              <h1>Welcome to</h1>
              <h1>Lissom Technologies</h1>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default HomeVideo;

