import React, { useState, useEffect } from "react";
import Projectvd from "../videos/sw.mp4";
import { Card } from "react-bootstrap";
import "../css/Servicesweoffer.css";
import Gypsumpng from "../images/mobile.jpg";
import Estimatepng from "../images/website.jpg";
import Estimate2png from "../images/smo.jpg";
import interiorpng from "../images/website2.jpg";
import Materialsupplypng from "../images/ecommerce.jpg";
import Carpentrypng from "../images/crm.jpg";
import Paintingpng from "../images/logodesign.jpg";
import { FaArrowRight } from "react-icons/fa";
import furniture from "../images/pexels-mikael-blomkvist-6476808.jpg";
import Aluminiumpng from "../images/electronics.jpg";
import Footer from "../components/Footer";
import { Link, Outlet } from "react-router-dom";
// import ApiConfig from "./ApiConfig";
import axios from "axios";

function Servicesweoffer() {
  const [servicesData, setServicesData] = useState([]);
  // const apiUrl = ApiConfig.apiUrl;
  useEffect(() => {
    // axios.get(`${apiUrl }/api/getservices`)
    axios
      .get("https://web.api.lissomtech.in/api/getservices")
      //axios.get('https://matoshreewebsite.lissomtech.in/APIMatoshree/api/events')
      .then((response) => {
        console.log("Fetched Data:", response.data);
        setServicesData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div class="header-intro">
      <Card className="video-card">
        <video autoPlay muted loop controls={false}>
          <source src={Projectvd} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Card>
      <br></br>
      <div className="container-fluid">
        <div className="text-project">
          <h1 className="text-project">Services We Offer</h1>
        </div>

        <div className="row" style={{ marginTop: "40px" }}>
          {servicesData.map((gypsum, index) => (
            <div key={index} className="col-md-4" style={{ marginTop: "40px" }}>
              <Link className="learn-more-link">
                <div className="card">
                  <img
                    src={`https://web.api.lissomtech.in/${gypsum.Service_img}`}
                    className="card-img-top"
                    alt={`Image ${index + 1}`}
                    style={{ height: "300px" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title services">
                      {gypsum.Service_Name}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <br></br>
      </div>

      <Footer />
    </div>
  );
}
export default Servicesweoffer;
