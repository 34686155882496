import "./App.css";
import Navbars from "./components/Navbars";
import Floatingicon from "./components/Floatingicon";
import Careers from "./components/Careers";
import Contactus from "./subcomponent/Contactus";
import Aboutus from "./subcomponent/Aboutus";
import SubProjects from "./subcomponent/SubProjects";
import Servicesweoffer from "./subcomponent/Servicesweoffer";
import Home from "./components/Home";
import Events from "./subcomponent/Events";
import Gallary from "./subcomponent/Gallery";
import AllPhotos from "./subcomponent/AllPhotos";
import Team from "./subcomponent/Team";
import Office from "./subcomponent/Office";
import InvitationLetter from "./subcomponent/InvitationLetter"
import UserInfo from "./subcomponent/UserInfo";
import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
function App() {


  return (
    <div className="App">
      <Navbars />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="aboutus" element={<Aboutus />} />
        <Route path="services" element={<Servicesweoffer />} />

        <Route path="projects" element={<SubProjects />} />
        <Route path="career" element={<Careers />} />
        <Route path="events" element={<Events />} />
        <Route path="contactus" element={<Contactus />} />
        <Route path="gallery" element={<Gallary />}>
          <Route index element={<AllPhotos />} />
          <Route path="allphotos" element={<AllPhotos />} />
          <Route path="team" element={<Team />} />
          <Route path="office" element={<Office />} />
        </Route>
        <Route path="tender/detailsoftender" element={<InvitationLetter />} />
        <Route path="/userinfo" element={<UserInfo />} />
      </Routes>
      <Floatingicon />
    </div>
  );
}

export default App;
